const TrainPartnes = () => {
  return (
    <div className="train-partners-container">
      <div className="text-content">
        <p>
          A nossa <strong>paixão pelo esporte</strong> que nos faz quebrar
          barreiras, nos permite aprender mais e sobretudo,
          <strong>não desistir</strong>. E agora, mais do que nunca,
          <strong>estamos juntos</strong> para enfrentar esse cenário!
        </p>

        <p>
          Por isso esportista, te convidamos a
          <strong>praticar exercícios em casa</strong> junto com a gente. Nós
          preparamos <strong>vídeos</strong> com nossos parceiros,
          <strong>dicas</strong> e produtos para você continuar
          <strong>em movimento</strong>.
        </p>

        <p>
          Não se esqueça de compartilhar conosco os seus treinos, marcando nas
          hashtags <strong>#treinoemcasa e #souesportista</strong>.
        </p>
      </div>
      <div className="igtv-content">
        <div className="img-content">
          <a
            href="https://www.instagram.com/decathlonbrasil/channel/ "
            className="gaClick"
            data-event-category="HP Bloco A-Posicao 1"
            data-event-action="Click Bloco A-Posicao 1"
            data-event-label="20200319-Mini Banner-Equipamentos de Ginastica"
          >
            <img
              src="https://decathlonpro.vteximg.com.br/arquivos/mobile-marcio-lui.png"
              alt="Equipamentos de Ginástica | Decathlon"
              height="429"
            />
          </a>
        </div>
        <div className="button-content">
          <h3>TREINE COM OS NOSSOS PARCEIROS</h3>
          <p>Confira os treinos diários que eles prepararam para você!</p>
          <a href="https://www.instagram.com/decathlonbrasil/channel/ ">
            <img
              src="https://decathlonpro.vteximg.com.br/arquivos/igtv-btn.png"
              alt="Igtv | Decathlon"
            />
          </a>
        </div>
      </div>
    </div>
  )
}

export default TrainPartnes

const DicasTreino = () => {
  return (
    <div className="dicas-container">
      <h2>Dicas para você não ficar parado!</h2>
      <p>Confira nossas sugestões para treinar em casa!</p>
      <div className="images-container">
        <a
          href="https://souesportista.decathlon.com.br/musculacao-utilizando-apenas-o-peso-do-corpo/"
          className="gaClick"
          data-event-action="Click Bloco Dicas treino em casa 1"
          data-event-label="Musculacao utilizando apenas o peso do corpo"
        >
          <img
            src="https://decathlonpro.vteximg.com.br/arquivos/dica-treino-em-casa-01.jpg"
            alt="Musculacao utilizando apenas o peso do corpo"
            width="580"
            height="350"
          />
        </a>
        <a
          href="https://souesportista.decathlon.com.br/60-exercicios-em-casa-com-superbands-por-aurelio-alfieri/"
          className="gaClick"
          data-event-category="Treine em casa"
          data-event-action="Click Bloco Dicas treino em casa 2"
          data-event-label="60 exercícios com a superband"
        >
          <img
            src="https://decathlonpro.vteximg.com.br/arquivos/dica-treino-em-casa-02.jpg"
            alt="60 exercícios com a superband"
            width="580"
            height="350"
          />
        </a>
        <a
          href="https://souesportista.decathlon.com.br/6-dicas-de-exercicios-cardio-com-equipamentos/"
          className="gaClick"
          data-event-category="Treine em casa"
          data-event-action="Click Bloco Dicas treino em casa 3"
          data-event-label="6 dicas de quipamentos cardio"
        >
          <img
            src="https://decathlonpro.vteximg.com.br/arquivos/dica-treino-em-casa-03.jpg"
            alt="6 dicas de quipamentos cardio"
            width="580"
            height="350"
          />
        </a>
      </div>
    </div>
  )
}

export default DicasTreino

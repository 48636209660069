const CompleteTrains = () => {
  return (
    <div className="complet-trains-container">
      <h2>Treinos completos</h2>
      <p> Confira nossos vídeos para você treinar sem sair de casa</p>
      <div className="complet-content">
        <a
          href="https://www.youtube.com/watch?v=J_VtldbXgpw"
          target="_blank"
          className="gaClick"
          data-event-category="Treino em casa"
          data-event-label="Video treino em casa"
          rel="noreferrer"
        >
          <img
            src="https://decathlonpro.vteximg.com.br/arquivos/video-treino-em-casa.jpg"
            alt="Equipamentos de Ginástica"
            width="580"
            height="350"
          />
        </a>
        <div className="complet-text">
          <div className="text-header">
            <h3>Treine com as MiniBands</h3>
            <p>
              {' '}
              As minibands são muito versáteis e fáceis de transportar. Você
              pode treinar quando e onde quiser com elas. Veja 09 exercícios
              exemplo de sua polivalência.
            </p>
          </div>
          <a
            href="https://www.youtube.com/user/mundodecathlon/videos"
            target="_blank"
            className="button button--medium button--blue"
            rel="noreferrer"
          >
            VER OS EXERCÍCIOS
          </a>
        </div>
      </div>
    </div>
  )
}

export default CompleteTrains

const TrainInHouse = () => {
  return (
    <div className="train-house-container">
      <h2>#TREINOEMCASA</h2>
      <p>
        Se inspire e compartilhe com a gente o seu treino!{' '}
        <span>
          Use as hashtags:
          <strong>#treinoemcasa #souesportista</strong>
        </span>
      </p>
      <a href="https://www.instagram.com/decathlonbrasil/">
        IR PARA @DECATHLONBRASIL
      </a>
    </div>
  )
}

export default TrainInHouse
